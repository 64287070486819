import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import HeroRatingStars from '../components/hero-rating-stars'

import ContactForm from '../components/estimate-form'
import { ServingCitiesText } from '../components/ServingCitiesText'

import Breadcrumbs from '../components/breadcrumbs'

import { Star } from 'react-feather'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'

import daysFromToday from '../helpers/daysFromToday'

const ContactCard = styled.div`
  top: 30px !important;
  background: rgb(249 249 249);
`

const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`

const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.25vw + 1.25em);
    line-height: 1.15;
    font-weight: 800;
    color: #000;
  }
  border-bottom: 1px solid #f1f1f1;

  background: #f7f7f7;
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

class OfferPostTemplate extends Component {
  render() {
    const post = this.props.data.contentfulOffer
    const siteurl = this.props.data.site.siteMetadata.siteUrl

    const { relatedProjects } = this.props.data

    return (
      <Layout pageProps={this.props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/offers/', label: 'Offers' },
                {
                  to: this.props.location.pathname,
                  label: post.title,
                },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={post.metaTitle ? post.metaTitle : post.title}
          description={post.metaDescription ? post.metaDescription : post.title}
          img={post.image.gatsbyImageData.images.fallback.src}
          twitterCardContent={'summary_large_image'}
        />
        <BlogPostLayout className="relative">
          <article itemScope itemType="https://schema.org/Article">
            <div
              itemProp="publisher"
              itemScope
              itemType="https://schema.org/Organization"
              className="hidden"
            >
              <span itemProp="name" className="hidden">
                Solid Construction & Design
              </span>
              <span
                itemProp="image"
                itemScope
                itemType="http://schema.org/ImageObject"
                className="hidden"
              >
                <img
                  src="https://images.ctfassets.net/555y3d6wo2js/687cWoNXYdUvjoPIKLH6ko/513b022817506fe1203cbb6dd98a885e/solid-thumb.jpg"
                  className="hidden"
                  width="262"
                  itemProp="url"
                />
              </span>
              <span
                itemProp="logo"
                itemScope
                itemType="http://schema.org/ImageObject"
                className="hidden"
              >
                <img
                  src="https://images.ctfassets.net/555y3d6wo2js/687cWoNXYdUvjoPIKLH6ko/513b022817506fe1203cbb6dd98a885e/solid-thumb.jpg"
                  className="hidden"
                  width="262"
                  itemProp="url"
                />
              </span>
            </div>
            <div className="container pt-4">
              <h1 className="text-2xl md:text-4xl font-bold">{post.title}</h1>
              {post.excerpt.excerpt && (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      post.excerpt.excerpt +
                      ' Hurry, offer expires ' +
                      (post.expiresOn
                        ? post.expiresOn
                        : daysFromToday(6).toLocaleDateString('en-US')),
                  }}
                ></span>
              )}
              <div className="grid md:grid-cols-2 mt-4 pb-4 gap-6">
                <div className="left">
                  <div
                    itemProp="image"
                    itemScope
                    itemType="http://schema.org/ImageObject"
                  >
                    <img
                      src={post.image.gatsbyImageData.images.fallback.src}
                      className="hidden"
                      width="900"
                      height="400"
                      itemProp="url"
                      alt={post.title}
                    />

                    <GatsbyImage
                      image={getImage(post.image)}
                      alt={post.title}
                      style={{
                        height: '400px',
                        objectFit: 'cover',
                        boxShadow: '1px 2px 8px 1px hsla(0,0%,0%,0)',
                      }}
                      className="blogPostImage border-radius shadow-md"
                    />
                  </div>
                  {post.body && (
                    <div
                      className="mt-4"
                      dangerouslySetInnerHTML={{ __html: post.body.body }}
                    />
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="offer-card flex flex-col relative text-white border-radius p-6">
                    <GatsbyImage
                      image={getImage(post.image)}
                      className="image-overlay border-radius shadow-md"
                    />
                    <div className="relative z-10">
                      <h2>{post.cardTitle}</h2>
                      {post.cardDescription && (
                        <span
                          className="text-base mb-6"
                          dangerouslySetInnerHTML={{
                            __html:
                              post.cardDescription +
                              ' Offer expires ' +
                              (post.expiresOn
                                ? post.expiresOn
                                : daysFromToday(6).toLocaleDateString(
                                    'en-US'
                                  )) +
                              '.',
                          }}
                        />
                      )}
                      <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col md:pr-6">
                          <div className="flex flex-col mt-4 mb-4">
                            <div
                              className="rating-stars-container flex self-center"
                              style={{ color: 'orange' }}
                            >
                              <Star fill={'currentColor'} />
                              <Star fill={'currentColor'} />
                              <Star fill={'currentColor'} />
                              <Star fill={'currentColor'} />
                              <Star fill={'currentColor'} />
                            </div>
                            <span
                              itemScope
                              className="rating-desc"
                              itemType="https://schema.org/Product"
                            >
                              <span className="hidden" itemProp="name">
                                Solid Construction & Design
                              </span>{' '}
                              <span
                                className="block text-center text-sm mt-2"
                                itemScope
                                itemProp="aggregateRating"
                                itemType="https://schema.org/AggregateRating"
                              >
                                Rated{' '}
                                <span itemProp="ratingValue">{REVIEW_AVG}</span>
                                /5 based on{' '}
                                <span itemProp="reviewCount">
                                  {TOTAL_REVIEW_COUNT}
                                </span>{' '}
                                reviews.{' '}
                              </span>
                            </span>
                          </div>
                          <div className="grid grid-cols-2 items-center md:flex md:flex-row md:flex-col mb-2">
                            <img
                              src="/assets/logos/bbb-white.svg"
                              width="100%"
                              loading="lazy"
                              alt="BBB Accredited Business"
                              className="mb-0 md:mb-4 w-full"
                            />
                            <img
                              src="https://images.ctfassets.net/wz4em6cftltu/2fGe4vsjxygR0uLvNdeXAC/38a3b8670bb8248717ee80ce8dd7527a/family-owned-white4.png"
                              width="100%"
                              loading="lazy"
                              alt="Proudly Family Owned & Operated"
                              className="mb-0 pl-4 py-4 pr-0 md:px-4 py-0 w-full"
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: '34px' }}>
                          <ContactForm
                            showPlaceHolders
                            pageLocation={
                              this.props.location.href
                                ? this.props.location.href
                                : 'Not provided'
                            }
                            offerPageStyle
                            style={'placeholder-text'}
                            prependDisclosureText={
                              'Offer may not be combined with any other promotions or discounts. '
                            }
                            actionButtonText={post.notificationBarActionText}
                            dark
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col p-3 border-radius shadow-md mt-4 mb-4">
                    <div className="flex justify-between items-center">
                      <span className="text-xl font-bold">Expires On</span>
                      <span className="text-base font-bold text-gray-700">
                        #{post.offerCode}
                      </span>
                    </div>
                    <span>
                      {post.expiresOn
                        ? post.expiresOn
                        : daysFromToday(6).toLocaleDateString('en-US')}
                    </span>
                  </div>

                  <ShareThisPost className="bg-white px-6 justify-content-end p-2">
                    <p className="text-base tracking-widest uppercase">
                      Share —{' '}
                    </p>
                    <FacebookShareButton
                      url={siteurl + '/offers/' + post.slug}
                      quote={post.title}
                      hashtag={'#solidconstructiondesign'}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Facebook size={20} />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={siteurl + '/offers/' + post.slug}
                      title={post.title}
                      hashtags={post.tags}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Twitter size={20} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={siteurl + '/offers/' + post.slug}
                      title={post.title}
                      quote={post.title}
                      hashtags={post.tags}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Linkedin size={20} />
                    </LinkedinShareButton>
                    <EmailShareButton
                      subject={post.title}
                      url={siteurl + '/offers/' + post.slug}
                      openWindow={true}
                      body={'Check out this offer: '}
                      className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                    >
                      <Mail size={20} className="stroke-white" />
                    </EmailShareButton>
                  </ShareThisPost>
                </div>
              </div>
            </div>
          </article>
        </BlogPostLayout>
        {relatedProjects && (
          <div className="container mx-auto p-4 py-8">
            <h3>Explore Our Recently Completed Projects</h3>
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
              {relatedProjects &&
                relatedProjects.edges &&
                relatedProjects.edges.map((item, i) => (
                  <Link
                    key={i}
                    to={`/projects/${item.node.slug}/`}
                    className="bg-white border-radius shadow hover:shadow-lg"
                  >
                    <GatsbyImage
                      image={getImage(item.node.heroImage)}
                      style={{ height: '150px' }}
                      alt={item.node.title + ' in ' + item.node.city}
                      className="border-radius-top"
                    />
                    <div className="p-3 py-4 pt-3">
                      <span className="text-gray-700 text-base">
                        {item.node.publishDate}
                      </span>
                      <span className=" font-semibold leading-tight text-gray-900 block">
                        {item.node.title}
                      </span>
                      <div className="flex items-center text-gray-800 text-xs mt-1">
                        <div className="">
                          <img
                            src="/assets/location-pin.svg"
                            className="w-2 mb-0"
                            alt={item.node.city}
                          />
                        </div>
                        <div
                          className="ml-2 text-xs"
                          itemprop="address"
                          itemscope
                          itemtype="https://schema.org/PostalAddress"
                        >
                          <span itemprop="addressLocality">
                            {item.node.city}
                          </span>{' '}
                          <span itemprop="postalCode">{item.node.zipCode}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default OfferPostTemplate

export const pageQuery = graphql`
  query OfferPostBySlug($slug: String!, $relatedProjectCategoryIds: [String]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulOffer(slug: { eq: $slug }) {
      id
      title
      slug
      expiresOn(formatString: "MMMM Do, YYYY")
      expiresFormatted: expiresOn(formatString: "MM/DD/YYYY")
      excerpt {
        excerpt
      }
      body {
        body
      }
      image {
        gatsbyImageData(width: 1500, quality: 70, placeholder: NONE)
      }
      metaTitle
      metaDescription
      offerCode
      cardTitle
      cardDescription
      # cardButtonActionText
      notificationBarActionText
    }
    relatedProjects: allContentfulProjects(
      filter: {
        category: {
          elemMatch: { contentful_id: { in: $relatedProjectCategoryIds } }
        }
      }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          slug
          date
          city
          zipCode
          heroImage: thumbnail {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
          }
        }
      }
    }
  }
`
